<!--  -->
<template>
  <div class="top">
    <div class="content">
      <header class="header-box">
        <span class="backList" @click="goBack">&lt; Back to lists</span>
        <div class="action-box">
          <h3>Create a list</h3>
          <el-button type="primary" size="mini" @click="submitForm('ruleForm')"
            >Next</el-button
          >
        </div>
      </header>
      <div class="main-box">
        <div class="aside">
          <div
            class="aside-select"
            @click="selectFlg = true"
            :class="selectFlg ? 'boderBule' : ''"
          >
            <svg class="icon" aria-hidden="true">
              <use href="#icon-search_min"></use>
            </svg>
            <div class="based-box">
              <div class="tipsTitle">Lead-based</div>
              <div class="tipsContent">Create a list of Contact records</div>
            </div>
          </div>
          <div
            class="aside-select"
            @click="selectFlg = false"
            :class="selectFlg ? '' : 'boderBule'"
          >
            <svg class="icon" aria-hidden="true">
              <use href="#icon-search_min"></use>
            </svg>
            <div class="based-box">
              <div class="tipsTitle">Lead-based</div>
              <div class="tipsContent">Create a list of Contact records</div>
            </div>
          </div>
        </div>
        <div class="container">
          <el-form
            ref="ruleForm"
            :model="formData"
            :rules="rules"
            :validate-on-rule-change="true"
          >
            <div class="container-title"><span>*</span>List name</div>
            <el-form-item prop="listName">
              <el-input
                type="text"
                class="name-input"
                style="min-height: 38px"
                v-model="formData.listName"
              />
            </el-form-item>
            <div class="select-remind">What kind of list are you creating?</div>
            <el-radio v-model="formData.radio" label="Active list"
              >Active list</el-radio
            >
            <div class="instructions">
              Active lists automatically update over time. Records will join or
              leave the list as their properties change.
            </div>
            <el-radio v-model="formData.radio" label="Static list"
              >Static list</el-radio
            >
            <div class="instructions">
              Static lists do not automatically update as your records change. A
              static list represents a single moment in time.
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      formData: {
        listName: "",
        radio: "Active list",
      },
      rules: {
        listName: [
          {
            required: true,
            message: "Please name this list before saving it",
            trgger: "change",
          },
        ],
      },
      selectFlg: false,
    };
  },
  //方法集合
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }
      });
      if (this.formData.listName !== "") {
        this.$router.push({
          name: "particulars",
          params: {
            id: this.formData.listName,
            title: this.formData.radio,
            acsta: true,
          },
        });
      }
    },
    goBack() {
      this.$router.push({
        name: "lists",
        params: {},
      });
    },
  },
  created() {},
};
</script>
<style lang='scss' scoped>
.el-tabs {
  margin-top: 15px;
  ::v-deep .el-tabs__item {
    font-size: 16px;
    padding-left: 28px;
    outline: none;
  }
  ::v-deep .el-tabs__item.is-active {
    font-weight: bolder;
    color: #080707;
  }
}
form {
  display: inline-block;
}
.boderBule {
  background: rgba(0, 109, 204, 0.07);
  border: 1px solid #006dcc !important;
}
.top {
  width: 100%;
  padding: 10px;
  height: 100%;
  .content {
    width: 100%;
    height: 100%;
    background: #ffffff;
    padding: 15px 8px 40px;
    border: 1px solid #dddbda;
    .header-box {
      border-bottom: 1px solid #dedcda;
      .action-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
          height: 30px;
          width: 100px;
        }
        h3 {
          font-family: SourceHanSansCN-Regular;
          font-size: 18px;
          color: #080707;
          height: 60px;
          line-height: 60px;
        }
      }
      .backList {
        cursor: pointer;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #006dcc;
        letter-spacing: 0;
      }
    }
    .main-box {
      height: 90%;
      .aside {
        width: 25%;
        height: 100%;
        float: left;
        border-right: 1px solid #dedcda;
        padding: 0px 10px;
        .aside-select {
          width: 100%;
          margin-top: 20px;
          height: 110px;
          border: 1px solid #dddbda;
          border-radius: 3px;
          border-radius: 3px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          .icon {
            width: 65px;
            height: 65px;
          }
          .based-box {
            .tipsTitle {
              font-family: Helvetica;
              font-size: 14px;
              color: #080707;
              line-height: 30px;
              height: 30px;
            }
            .tipsContent {
              font-family: MicrosoftYaHei;
              font-size: 12px;
              color: #888888;
              line-height: 30px;
              height: 30px;
            }
          }
        }
        .aside-select:hover {
          border: 1px solid #006dcc;
        }
      }
      .container {
        margin-left: 25%;
        width: 75%;
        height: 100%;
        padding: 20px 10px;
        .container-title {
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #080707;
          line-height: 16px;
          height: 50px;
          line-height: 50px;
          span {
            color: #c12217;
          }
        }
        .name-input {
          border-radius: 3px;
          border-radius: 3px;
          width: 310px;
          height: 35px;
        }
        .select-remind {
          font-family: SourceHanSansCN-Medium;
          font-size: 14px;
          color: #080707;
          height: 60px;
          line-height: 60px;
        }
        .instructions {
          font-family: SourceHanSansCN-Normal;
          font-size: 12px;
          color: #888888;
          height: 40px;
          line-height: 40px;
          margin-left: 25px;
        }
      }
    }
  }
}
</style>